import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../styles/helpers";
import text from "../ProductList/ProductList.typography";
import { Guide } from "@xxl/icons";

export const Link = styled.a`
  width: 100%;
  display: flex;
  margin: var(--space-small-regular) 0;
  padding: var(--space-small-regular);
  ${MQ("tablet")} {
    margin: var(--space-large) 0;
  }
`;

export const TextWrapper = styled.span`
  ${typographyToCss(text.button)};
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-left: var(--space-mini);
  text-decoration: none;
  justify-content: space-between;
`;

export const IconNewTab = styled.img`
  margin-left: var(--space-mini);
  margin-top: var(--space-hair);
  width: var(--space-small-regular);
  height: var(--space-small-regular);
`;

export const CampaignIcon = styled.img`
  max-height: 30px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const NewsletterIcon = styled(Guide)`
  height: 15px;
`;

export const StyledNewsletterLink = styled.a`
  &&& {
    width: 100%;
    display: flex;
    margin: 0 0 var(--space-small-regular);
    border: 1px solid var(--color-xxl-gray);
  }

  ${MQ("tablet")} {
    &&& {
      width: auto;
      margin: var(--space-large) 0 0;
      border: none;
    }
  }
`;
