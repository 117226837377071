import * as React from "react";
import { CategoryName, ProductCount, ListItem } from "./SelectorItem.styled";

type CategorySelectorItemProps = {
  handleClick: () => void;
  isSelected: boolean;
  isVisible: boolean;
  type?: "all" | "default";
  name: string;
  isClickable: boolean;
  icon: JSX.Element | null;
  productCount?: number;
};

const BASE_CLASS_NAME = "category-selector__link";
const ITEM_CLASS_NAME = "category-selector__item";
const ICON_CLASS_NAME = "category-selector__icon";
const SELECTED_CLASS_NAME = `${BASE_CLASS_NAME}--selected`;
const CLICKABLE_CLASS_NAME = `${BASE_CLASS_NAME}--clickable`;

const SelectorItem: React.FunctionComponent<CategorySelectorItemProps> = ({
  handleClick,
  isClickable,
  isSelected,
  type = "default",
  isVisible,
  name,
  icon,
  productCount,
}): JSX.Element => {
  const onClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    if (!isClickable) {
      return;
    }
    handleClick();
  };

  const className = `${BASE_CLASS_NAME}${
    isSelected ? ` ${SELECTED_CLASS_NAME}` : ""
  }${isClickable ? ` ${CLICKABLE_CLASS_NAME}` : ""}`;

  const itemClassName = `${ITEM_CLASS_NAME} ${
    isSelected ? ITEM_CLASS_NAME + "--selected" : ""
  } `;

  const iconClassName = `${ICON_CLASS_NAME} ${
    type === "all" ? ICON_CLASS_NAME + "--invert" : ""
  } `;

  return (
    <ListItem
      data-testid={isSelected ? "is-selected-item" : ""}
      show={isVisible}
      isClickable={isClickable}
      animationDurationMs={200}
      className={itemClassName}
    >
      <button type="button" className={className} onClick={onClick}>
        {icon !== null && <span className={iconClassName}>{icon}</span>}
        <CategoryName>{name}</CategoryName>
        {productCount !== undefined && (
          <ProductCount>({productCount})</ProductCount>
        )}
      </button>
    </ListItem>
  );
};

export { SelectorItem, SELECTED_CLASS_NAME, CLICKABLE_CLASS_NAME };
