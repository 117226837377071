import styled from "@emotion/styled/macro";
import { MQ } from "../../../styles/helpers";
import Slider from "react-slick";
import { xxlTheme } from "../../../styles/xxl-theme";
import spaces from "../../../styles/theme/spaces";

const { colors } = xxlTheme;

export const CategoryList = styled.ul`
  margin-bottom: ${spaces.large};

  ${MQ("tablet")} {
    margin-top: ${spaces.large};
  }

  ${MQ("bigDesktop")} {
    margin-top: ${spaces.big};
  }
`;

export const SliderList = styled(Slider)`
  width: 100%;
  list-style-type: none;

  & .slick-slide:not(:last-of-type) {
    margin-right: 6px;
  }

  & .slick-list {
    overflow: hidden;
  }
  .slick-dots {
    li {
      &.slick-active {
        button {
          // Didn't find better way to overwrite this property
          background-color: ${colors.xxlBlack} !important;
        }
      }
    }
  }
`;
