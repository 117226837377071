import React from "react";
import type {
  CountdownTimerSettings,
  DisclaimerInformation as DisclaimerInformationType,
  Image,
} from "@xxl/content-api";
import { CountdownTimer } from "../CountdownTimer";
import {
  BackgroundWrapper,
  CountdownTimeContainer,
  Description,
  DisclaimerInformationWrapper,
  Headline,
  Label,
  TextWrapper,
  Wrapper,
  disclaimerInfoIconSize,
} from "./CampaignHeroBanner.styled";
import { useMediaQuery } from "@mui/material";
import {
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from "../../utils/xxl-screen";
import { NewsletterLink } from "next-js-app/src/components/digitalCampaign/NewsletterLink";
import { DisclaimerInformation } from "../DisclaimerInformation";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { hasValue } from "@xxl/common-utils";
import { getImageUrl } from "react-app/src/utils/xxl-image";

type CampaignHeroBannerProps = {
  title: string;
  textColor: "black" | "white";
  imageOverlay: boolean;
  textAlignment: "center" | "left";
  mobileImage?: Image;
  desktopImage?: Image;
  label?: string;
  description?: string;
  countdownTimerSettings?: CountdownTimerSettings | null;
  backgroundColor?: string;
  ipaperLink?: string;
  disclaimerInformation?: DisclaimerInformationType;
};

const CampaignHeroBanner = ({
  label,
  title,
  description,
  textAlignment,
  desktopImage,
  mobileImage,
  textColor,
  imageOverlay,
  countdownTimerSettings,
  backgroundColor,
  ipaperLink,
  disclaimerInformation,
}: CampaignHeroBannerProps) => {
  const { t } = useTranslations();
  const isMobileOrTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const showLabel =
    (isMobileOrTablet && countdownTimerSettings === null) || !isMobileOrTablet;
  const isMobile = useMediaQuery(mobileMediaQuery);
  const disclaimerInformationIconSize = isMobile
    ? disclaimerInfoIconSize.mobile
    : disclaimerInfoIconSize.desktop;
  const hasDisclaimerInformation = hasValue(disclaimerInformation);

  return (
    <Wrapper className="container">
      <BackgroundWrapper
        imageOverlay={imageOverlay}
        backgroundColor={backgroundColor}
        desktopImageUrl={
          hasValue(desktopImage) ? getImageUrl(desktopImage) : undefined
        }
        mobileImageUrl={
          hasValue(mobileImage) ? getImageUrl(mobileImage) : undefined
        }
      >
        {hasDisclaimerInformation && (
          <DisclaimerInformationWrapper>
            <DisclaimerInformation
              buttonText={t("general.close")}
              title={t("general.information")}
              iconSize={disclaimerInformationIconSize}
              color={disclaimerInformation.buttonColor}
            >
              {disclaimerInformation.description}
            </DisclaimerInformation>
          </DisclaimerInformationWrapper>
        )}

        <TextWrapper
          textAlignment={textAlignment}
          textColor={textColor}
          hasDisclaimerInformation={hasDisclaimerInformation}
        >
          {showLabel && <Label>{label}</Label>}
          <Headline>{title}</Headline>

          {countdownTimerSettings !== null &&
            countdownTimerSettings?.date !== undefined && (
              <CountdownTimeContainer>
                <CountdownTimer
                  date={new Date(countdownTimerSettings.date)}
                  isBlackText={textColor === "black"}
                  isOnlyHours={countdownTimerSettings.isOnlyHours ?? false}
                />
              </CountdownTimeContainer>
            )}
          <Description>{description}</Description>
          {ipaperLink !== undefined && !isMobile && (
            <NewsletterLink url={ipaperLink} />
          )}
        </TextWrapper>
      </BackgroundWrapper>
    </Wrapper>
  );
};

export default CampaignHeroBanner;
