import * as React from "react";
import {
  StyledNewsletterLink,
  TextWrapper,
  NewsletterIcon,
} from "react-app/src/components/CampaignPaper/CampaignPaper.styled";

import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";

const NewsletterLink = ({ url }: { url: string }) => {
  const { t } = useTranslations();

  return (
    <StyledNewsletterLink
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      className="button button--small button--secondary"
    >
      <NewsletterIcon />
      <TextWrapper>{t("campaign.newsletter.link")}</TextWrapper>
    </StyledNewsletterLink>
  );
};

export { NewsletterLink };
