export default {
  button: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 15,
      lineHeight: 1,
    },
  },
};
