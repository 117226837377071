import { useEffect } from "react";
import { scrollToPreviousPosition } from "../../../components/Search/SearchScrollHelper";

/**
 * Scroll user back to previous position when browser loads content from cache
 * https://gomakethings.com/fixing-safaris-back-button-browser-cache-issue-with-vanilla-js/
 */
const useScrollRestoration = () => {
  useEffect(() => {
    const pageShowCallback = (event: PageTransitionEvent) => {
      if (!event.persisted) {
        return;
      }
      scrollToPreviousPosition();
    };
    window.addEventListener("pageshow", pageShowCallback);
    return (): void => window.removeEventListener("pageshow", pageShowCallback);
  }, []);
};

export { useScrollRestoration };
