import styled from "@emotion/styled/macro";
import { resolutionSizes } from "../../../config";

export const CategoryName = styled.span`
  max-width: 100%;
  margin: 0;
  user-select: none;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;

  &:first-letter {
    text-transform: capitalize;
  }

  @media (max-width: ${resolutionSizes.tablet}px) {
    align-self: inherit;
  }
`;

export const ProductCount = styled(CategoryName)`
  margin-left: 5px;
  word-break: normal;

  @media (min-width: ${resolutionSizes.tablet}px) {
    margin-left: 0;
  }
`;

type ListItemProps = {
  animationDurationMs: number;
  isClickable: boolean;
  show: boolean;
};
export const ListItem = styled.li<ListItemProps>`
  @keyframes opacity-hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes opacity-show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: ${({ show, animationDurationMs }) =>
    `opacity-${show ? "show" : "hide"} ${animationDurationMs / 1000}s both`};
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "none")};
`;
