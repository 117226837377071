import type { GridComponent as GridComponentType } from "@xxl/content-api";
import React from "react";
import { withErrorBoundary } from "../../utils/WithErrorBoundary/with-error-boundary";
import { ErrorBoundary } from "../Common/ErrorBoundary/ErrorBoundary";
import type { GridComponentProps } from "../GridComponent/GridComponent";
import GridComponent from "../GridComponent/GridComponent";
import { ComponentWrapper, ContainerWrapper } from "./GridContainer.styled";

export type GridContainerProps = {
  gridComponents: GridComponentType[];
} & Omit<GridComponentProps, "banners">;

const GridContainer: React.FunctionComponent<GridContainerProps> = ({
  gridComponents,
  priceData,
}) =>
  gridComponents.length > 0 ? (
    <ContainerWrapper>
      {gridComponents.map(
        (item, index) =>
          item.gridBanners !== undefined && (
            <ComponentWrapper key={index}>
              <ErrorBoundary>
                <GridComponent
                  banners={item.gridBanners}
                  priceData={priceData}
                />
              </ErrorBoundary>
            </ComponentWrapper>
          )
      )}
    </ContainerWrapper>
  ) : null;

export default withErrorBoundary(GridContainer);
