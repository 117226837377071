import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  ContentModule,
  RichText,
  GridContainer as GridContainerType,
} from "@xxl/content-api";

import React from "react";
import type { GridContainerProps } from "../GridContainer/GridContainer";
import GridContainer from "../GridContainer/GridContainer";
import {
  Link,
  RichTextFieldWrapper,
  SectionName,
  SectionWrapper,
} from "./GridSection.styled";
import ReactHtmlParser from "html-react-parser";

type GridContainerAllType = NonNullable<ContentModule["gridContainers"]>[0];

type GridSectionProps = {
  buttonUrl: string | null;
  gridContainers: GridContainerAllType[];
  name: string;
  visibleContainersCount?: number;
  buttonText?: string;
  isLastSection?: boolean;
} & Omit<GridContainerProps, "gridComponents">;

export const GridSection: React.FunctionComponent<GridSectionProps> = ({
  buttonText,
  buttonUrl,
  gridContainers,
  isLastSection = false,
  name,
  priceData,
  visibleContainersCount,
}) => {
  const visibleContainers =
    visibleContainersCount === undefined ||
    visibleContainersCount === gridContainers.length
      ? gridContainers
      : gridContainers.slice(0, visibleContainersCount);

  return (
    <SectionWrapper className="component-standard-spacing container">
      <SectionName>{name}</SectionName>
      {visibleContainers
        .map((item, index) => {
          const type = item._type;

          if (type === "RichText") {
            const { text } = item as RichText;
            return (
              <RichTextFieldWrapper key={item._key}>
                {ReactHtmlParser(text ?? "")}
              </RichTextFieldWrapper>
            );
          }

          if (type === "gridContainer") {
            const { gridComponents } = item as GridContainerType;

            if (gridComponents === undefined) {
              return null;
            }

            return (
              <GridContainer
                key={index}
                gridComponents={gridComponents}
                priceData={priceData}
              />
            );
          }

          return null;
        })
        .filter(hasValue)}
      {isNotNullOrUndefined(buttonText) && isNotNullOrUndefined(buttonUrl) && (
        <Link
          noBottomMargin={isLastSection}
          href={buttonUrl}
          className="button button--primary button--large"
        >
          {buttonText}
        </Link>
      )}
    </SectionWrapper>
  );
};
